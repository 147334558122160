import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {
    SET_CURRENT_HOMEWORK,
    UPDATE_HOMEWORK,
} from "../../redux/actions/homework-actions";

import {connect} from "react-redux";

import {
    CREATE_HOMEWORK_CONFIG,
    UPDATE_HOMEWORK_CONFIG,
} from "../../redux/actions/configurator-actions";
import Loading from "../Loading";

const SaveButton = ({
                        loadingCreateUpdateHomeworkConfig,
                        currentHomework,
                        assignment,
                        config,
                        editorRawData,
                        creatingConfigProgress,
                        updateHomework,
                        updateHomeworkConfig,
                        createHomeworkConfig,
                        setCurrentHomework,
}) => {
    const [buttonState, setButtonState] = useState(null);

    const loading = loadingCreateUpdateHomeworkConfig;
    const progress = creatingConfigProgress;

    useEffect(() => {
        if (loadingCreateUpdateHomeworkConfig === false) {
            setButtonState(null);
        }
    }, [loadingCreateUpdateHomeworkConfig]);

    const saveConfig = () => {
        setButtonState('loading');

        const modifiedAssignment = assignment.map((row) =>
            row.map((element) => {
                if (element.isAnswer) {
                    return {
                        color: element.color,
                        isAnswer: element.isAnswer,
                        result: '',
                        studentAnswer: '',
                        text: element.style === 'TRUE-FALSE' ? element.text.toLowerCase() : element.text,
                        correct: element.correct ? element.correct : null,
                        style: element.style,
                    };
                }
                return element;
            })
        );

        let media = {};
        if (config.withMedia) {
            if (config.isVideo) {
                media = {
                    type: 'video',
                    videoLink: config.videoLink,
                    startTimeMin: config.startTimeMin || 0.0,
                    startTimeSec: config.startTimeSec || 0.0,
                    startTime: config.startTime || 0.0,
                    endTimeMin: config.endTimeMin || 0.0,
                    endTimeSec: config.endTimeSec || 0.0,
                    endTime: config.endTime || 0.0,
                };
            } else if (config.isImage) {
                media = {
                    type: 'image',
                    image: config.image,
                };
            }
        }

        if (config.type === 'quizlet') {
            media = {
                type: 'quizlet',
                quizletId: config.quizletId,
                quizletType: config.quizletType,
                quizletLink: config.quizletLink,
            };
        } else {
            media.taskTitle = config.taskTitle;
        }

        const newConfig = {
            type: config.type,
            media: media,
            background:
                config.background === null || config.type === 'quizlet'
                    ? { type: 'color', source: 'rgb(255, 255, 255)' }
                    : config.background,
            assignment: modifiedAssignment,
            editorRawData: editorRawData,
            homework: currentHomework.id,
        };

        if (config.isExist) {
            updateHomeworkConfig(newConfig);
        } else {
            createHomeworkConfig(newConfig);
        }

        if (currentHomework.title !== config.title) {
            const updatedHomework = { ...currentHomework, title: config.title };
            setCurrentHomework(updatedHomework);
            updateHomework(updatedHomework);
        }
    };

    return (
        <React.Fragment>
            <button
                type="button"
                onClick={saveConfig}
                className="btn btn-outline btn-lg"
            >
                {config.isExist
                    ? loading && buttonState && <Loading size="xs" />
                    : progress?.progress && !progress?.complete && (
                    <div
                        className="radial-progress"
                        style={{
                            '--value': progress?.progress?.percent || 70,
                            '--size': '2.0rem',
                            '--thickness': '4px',
                        }}
                        role="progressbar"
                    >
                        {progress?.progress.current}
                    </div>
                )}
                Save
            </button>
        </React.Fragment>
    );
}

SaveButton.propTypes = {
    needToStore: PropTypes.bool.isRequired,
    loadingCreateUpdateHomeworkConfig: PropTypes.bool,
    currentHomework: PropTypes.object,
    title: PropTypes.string,
    assignment: PropTypes.array,
    config: PropTypes.object,
    editorRawData: PropTypes.object,
    updateHomework: PropTypes.func,
    updateHomeworkConfig: PropTypes.func,
    createHomeworkConfig: PropTypes.func,
    setCurrentHomework: PropTypes.func,
    creatingConfigProgress: PropTypes.object,
}

const mapStateToProps = (state) => ({
    loadingCreateUpdateHomeworkConfig: state.config.loadingCreateUpdateHomeworkConfig,
    createUpdateHomeworkConfigError: state.config.createUpdateHomeworkConfigError,
    currentHomework: state.homework.currentHomework,
    title: state.homework.title,
    editorRawData: state.config.editorRawData,
    assignment: state.config.assignment,
    config: state.config.config,
    creatingConfigProgress: state.config.creatingConfigProgress

})

const mapDispatchToProps = (dispatch) => ({
    updateHomework: (currentHomework) => dispatch({ type: UPDATE_HOMEWORK, payload: currentHomework }),
    setCurrentHomework: (currentHomework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: currentHomework }),
    updateHomeworkConfig: (config) => dispatch({ type: UPDATE_HOMEWORK_CONFIG, payload: config }),
    createHomeworkConfig: (config) => dispatch({ type: CREATE_HOMEWORK_CONFIG, payload: config }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton)
